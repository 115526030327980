<template>


    <div class="header">
        <!--        <div class="guang-gao" v-if="ggFlag">-->
        <!--            <el-button type="text" class="close " @click="closegg">关闭</el-button>-->
        <!--            <a href="">-->
        <!--                <img src="@/assets/infomercial/guanggao.png" alt="">-->
        <!--            </a>-->
        <!--        </div>-->
        <el-menu class="nav" mode="horizontal" text-color="#404040"
                 default-active="activeIndex" @select="handleSelect"
                 active-text-color="#409EFF">
            <el-menu-item index="0" class="nav_logo" @click="toHome()">
                <div>青少年编程考试模拟教学平台</div>
            </el-menu-item>
            <el-menu-item index="1">
                <div class="nav_search" v-if="this.$route.name ==='homePage'">
                    <el-input class="nav_search_input" :class="{'input-highlighted': inputHighlighted}"
                              type="text" clearable
                              v-model="searchPaperName" maxlength="40"
                              placeholder="    请输入试卷标题">
                    </el-input>
                    <button class="button" @click="selectPaperName">搜索</button>
                </div>
                <div v-else style="width: 300px">

                </div>
            </el-menu-item>
            <el-menu-item index="2" @click="toHome()"
                          class="right-align">首页
            </el-menu-item>

            <el-menu-item index="3" @click="toPaperList()" class="right-align">试卷中心
            </el-menu-item>
            <el-menu-item index="4" @click="toQuestionBank()" class="right-align">题库搜题</el-menu-item>
            <el-menu-item index="5" @click="toExaminationRecord()" class="right-align">考试记录</el-menu-item>
            <el-menu-item index="6" @click="toWrongBook()" class="right-align" v-if="this.$route.name !=='homePage'">
                错题本
            </el-menu-item>

            <el-menu-item index="8" v-if="!titleShow && this.$route.name !=='homePage'" @click="toTeacherManage()">
                教学中心
            </el-menu-item>

            <el-menu-item index="7" v-if="!titleShow" @click="toSelfVIP()" class="nav_vip right-align"><img
                    class="vip_img" src="@/assets/vip5.svg">会员中心
            </el-menu-item>
            <el-menu-item index="20" v-if="titleShow" @click="toMyWork()">我的作业</el-menu-item>
            <el-menu-item index="9" @click="toNotice()" v-if="this.$route.name !=='homePage'"
                          style="padding-right:10px">公告
            </el-menu-item>
            <el-menu-item index="10" class="nav_user">
                <div v-if="haveToken">
                    <el-popover placement="bottom" trigger="click"
                                popper-class="custom-popper"
                                ref="popover"
                    >
                        <template #reference>
                            <el-image style="width: 36px; height: 36px;"
                                      :src="iconUrl"
                                      fit="fill"></el-image>
                        </template>
                        <div style="text-align: center;">
                            <router-link :to="{ name: 'selfInfo', params: { activeName: 'personalInfo' } }"
                                         class="nav_user_detail">个人中心
                            </router-link>
                            <router-link to="/examinationRecord" class="nav_user_detail">考试记录</router-link>
                            <router-link to="/wrongBook" class="nav_user_detail">错题本</router-link>
                            <router-link :to="{ name: 'selfInfo', params: { activeName: 'classDetails' } }"
                                         class="nav_user_detail" @click="closePopover">我的课程
                            </router-link>
                            <p style="cursor:pointer" @click="logoutSys()">
                                <router-link to="/" class="nav_user_detail">退出登录</router-link>
                            </p>
                        </div>
                    </el-popover>
                </div>
                <div v-if="!haveToken">
                    <el-button type="warning" size="mini"
                               @click="login()">登录/注册
                    </el-button>
                </div>
            </el-menu-item>
        </el-menu>
    </div>
</template>

<script>
    import {logoutSystem, homeTitle, homePaper} from "@/api/index.js"

    export default {
        data() {
            return {
                activeIndex: '2',
                searchPaperName: '',
                visible: false,
                ggFlag: true,
                inputHighlighted: false,
                input: "",
                iconUrl: require('@/assets/boy.png'),
                haveToken: false,
                titleShow: false,
                title: "青少年编程考试模拟教学平台"
            }
        },
        watch: {
            loginDialogShow: {
                handler: function (n, o) {
                    this.getToken()
                }
            }
        },
        props: ["loginDialogShow"],
        mounted() {
            this.getToken()
            // this.getTitle()
        },
        methods: {
            closegg() {
                this.ggFlag = false
            },
            login() {
                this.$emit("clickLogin")
            },
            getToken() {
                if (localStorage.getItem("token")) {
                    this.haveToken = true;
                    if ("2" === localStorage.getItem("sex")) {
                        this.iconUrl = require('@/assets/girl.png')
                    } else {
                        this.iconUrl = require('@/assets/boy.png')
                    }
                    if (localStorage.getItem("agentFlag") === '1') {
                        if (localStorage.getItem("teacherFlag") === '0') {
                            this.titleShow = true;
                        }
                    }

                } else {
                    this.haveToken = false
                }
            },
            getTitle() {
                this.loading = true;
                homeTitle().then(res => {
                    if (res.code === "0000") {
                        this.loading = false;
                    }
                })
            },
            searchPaperOrQuestion() {
                this.inputHighlighted = true
                console.log(this.inputHighlighted)
            },
            handleSelect(key, keyPath) {
                this.activeIndex = key
            },
            toHome() {
                this.$router.push({name: 'homePage'})
                window.scrollTo(0, 0)
            },
            selectPaperName() {
                this.$router.push({
                    name: 'testPaperCenter',
                    query: {
                        searchPaperName: this.searchPaperName
                    }
                });
                window.scrollTo(0, 0);
                this.searchPaperName = '';

            },
            toPaperList() {
                this.$router.push({name: 'testPaperCenter'})
                window.scrollTo(0, 0)
            },
            toExaminationRecord() {
                this.$router.push({name: 'examinationRecord'})
                window.scrollTo(0, 0)
            },
            toWrongBook() {
                this.$router.push({name: 'wrongBook'})
                window.scrollTo(0, 0)
            },
            toQuestionBank() {
                this.$router.push({name: 'questionBank'})
                window.scrollTo(0, 0)
            },
            toMyWork() {
                this.$router.push({name: 'myWork'})
                window.scrollTo(0, 0)
            },
            toTeacherManage() {
                this.$router.push({name: 'teacherManage'})
                window.scrollTo(0, 0)
            },
            toSelfVIP() {
                this.$router.push({name: 'selfVIP'})
                window.scrollTo(0, 0)
            },
            toCourseSelectionCenter() {
                this.$router.push({name: 'courseSelectionCenter'})
                window.scrollTo(0, 0)
            },
            toShoppingMall() {
                this.$router.push({name: 'shoppingMall'})
                window.scrollTo(0, 0)
            },
            toNotice() {
                this.$router.push({name: 'notice'})
                window.scrollTo(0, 0)
            },
            logoutSys() {
                this.haveToken = false;
                logoutSystem().then(res => {
                    if (res.code === "0000") {
                        this.$message.success("成功退出")
                        this.iconUrl = ''
                        localStorage.setItem("token", "");
                        localStorage.removeItem("userId")
                        localStorage.removeItem("userVipType")
                        localStorage.removeItem("userId")
                        localStorage.removeItem("username")
                        localStorage.removeItem("agentFlag")
                        localStorage.removeItem("teacherFlag")
                    }
                })
            },
            closePopover() {
                this.$refs.popover.doClose();
            }

        }
    }
</script>

<style>
    .custom-popper {
        padding: 0;
    }

    /*.el-menu-item::after {*/
    /*    display: none;*/
    /*}*/

    /*.el-menu--collapse .el-menu .el-submenu, .el-menu--popup {*/
    /*    min-width: 120px !important;*/
    /*}*/

    /*点击标题文字 字体颜色发生变化*/
    .el-menu--horizontal > .el-menu-item:hover {
        /*color: #409EFF !important;*/
    }

    /* 去除标题下划线*/
    .el-menu--horizontal.nav > .el-menu-item {
        border-bottom: none;
        /*text-decoration: none;*/
    }


    .el-menu--horizontal.nav > .el-menu-item.is-active {
        border-bottom: none;
        /*text-decoration: none;*/
        color: #409EFF;
    }

    /*修改 el-input样式*/
    .el-input.input-highlighted .el-input__inner {
        box-shadow: 0 0 5px rgba(24, 144, 255, 0.7); /* 点击时的阴影效果 */
    }

    .el-input.nav_search_input .el-input__inner {
        height: 40px;
        border: 1px solid #409EFF !important;
        /*border: 1px solid #67C23A !important;*/
        border-radius: 4px 0 0 4px;
        border-right: none !important;
        text-decoration: none;
    }


    .el-input.nav_search_input .el-input__inner,
    .button {
        margin: 0;
        padding: 0;
    }

    .el-row {
        display: flex;
        align-items: center;
    }

    .el-row > .el-input {
        flex: 1;
        /*margin-right: 8px; !* 可以根据需要调整间距 *!*/
    }

</style>
<style lang="scss" scoped>

    ::v-deep .el-divider--horizontal {
        // margin: 0;
        margin-top: 10px;
        margin-bottom: 10px;
        // padding-bottom: 10px;
    }

    .header {
        height: 60px;
        background: #fff;
        color: #333;
        width: 100%;
    }

    .guang-gao {
        position: relative;
        width: 100%;
        display: flex;
        justify-content: center;
        z-index: 999;

        .close {
            position: absolute;
            top: 10px;
            right: 20px;
            line-height: 20px;
            color: #fff;
            font-size: 14px;
            cursor: pointer;
            font-style: normal;
        }

        a {
            background: transparent;
            color: #333;
            cursor: pointer;
            text-decoration: none;

            display: inline-block;
            max-width: 100%;
            max-height: 150px;

            img {
                border: 0;
                vertical-align: middle;

                display: block;
                max-height: 150px;
                max-width: 100%;

            }
        }
    }

    .nav {
        text-decoration: none;
        width: 1200px;
        margin: 0 auto; /*水平居中*/
        display: flex;
        justify-content: space-between;

        .nav_logo {
            width: 208px;
        }

        .nav_search {
            margin-left: 82px;
            margin-right: 20px;

            .nav_search_input {
                width: 300px;
            }

            .button {
                width: 70px;
                height: 40px;
                color: #FFF;
                background-color: #409EFF;
                border: 1px solid #409EFF;
                border-radius: 0 4px 4px 0;
                cursor: pointer;
                margin-top: 2.18px;
            }
        }
    }

    /* 导航栏的间距等样式 */
    .el-menu-item {
        padding: 0 5px;
        font-size: 16px;
        display: flex;
        align-items: center;

    }

    .el-menu--horizontal > .el-menu-item.nav_logo {
        padding-left: 0;
    }

    .el-menu--horizontal > .el-menu-item.nav_vip {
        margin-right: 0;

    }

    .vip_img {
        width: 30%;
        height: 30%;
        padding-right: 2px;
    }


    .nav_user_detail {

        display: block;
        line-height: 40px;
        height: 40px;
        text-decoration: none;
        color: #606266;

        &:hover {
            color: #1890ff;
            background-color: #E5E5E5;
        }

    }

</style>

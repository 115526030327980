<template>
    <div id="app">
        <div>
            <homeTitle :loginDialogShow="loginDialogShow" @clickLogin="clickLogin" class="homeTitleStyle"></homeTitle>
            <router-view class="routerViewStyle"/>
            <homeBottom v-if="isBottomLoaded" @clickBottomItem="clickBottomItem"></homeBottom>
            <!-- <button @click="clearSession">清除</button> -->
            <loginDialog :loginDialogShow="loginDialogShow" @closeDialog="closeDialog" @loginSuccess="loginSuccess">
            </loginDialog>
            <rightBlock style="position:fixed;top: 0;right: 0;height: 100%;z-index: 999;"></rightBlock>
        </div>


        <el-dialog title="版权与免责声明" :visible.sync="exemptionDialogVisible" width="40%" center>
            <p style="font-size:16px;margin: 0 auto;">本网站发布的所有作品， 均为上海玩扣教育科技有限公司原创或获得正版授权刊载或网友投稿的作品，
                未经本公司授权不得转载、摘编或利用其它方式使用上述作品。 已经本公司授权使用作品的，应在授权范围内使用，
                并注明“来源： 玩扣教育”。违反上述声明者， 本公司将追究其相关法律责任。</p>
            <p style="font-size:16px;margin: 0 auto;margin-top: 20px;">本公司在制作上述作品时，采用了部分来源网络的内容， 该部分内容并未标明出处导致原作者不详，
                因而本公司未能获取相应的授权。 本公司非常重视知识产权问题，
                尊重原作者的智力成果，如果本司的上述作品使用了您的版权内容， 请您及时与本公司联系，本公司将立即与您协商解决版权相关事宜。</p>
            <p style="font-size:16px;margin: 0 auto;margin-top: 20px;">邮箱：wancode@163.com</p>
            <p style="font-size:16px;margin: 0 auto;margin-top: 20px;text-align: right">上海玩扣教育科技有限公司</p>
            <p style="font-size:16px;margin: 0 auto;margin-top: 20px;text-align: right">2019年1月</p>
        </el-dialog>

        <el-dialog title="公司简介" :visible.sync="companyProfileVisible" width="40%" center>
            <p style="font-size:16px;margin: 0 auto;">上海玩扣教育科技有限公司，简称"玩扣"。</p>
            <p style="font-size:16px;margin: 0 auto;">玩扣是一款创新的少儿机器人编程教育品牌。</p>
            <p style="font-size:16px;margin: 0 auto;">我们的愿景，是让每一个孩子都学会编程，给孩子创造未来的力量。</p>
            <p style="font-size:16px;margin: 0 auto;">玩扣少儿编程教育，不只是少儿时一段暂时的经历，</p>
            <p style="font-size:16px;margin: 0 auto;">而是人工智能时代助人前行的动力；</p>
            <p style="font-size:16px;margin: 0 auto;">人工智能时代的到来，编程将成为未来世界的通用语言，是未来的沟通工具。</p>
            <p style="font-size:16px;margin: 0 auto;">以前，我们说孩子要与世界沟通，必须学会英语；</p>
            <p style="font-size:16px;margin: 0 auto;">在互联网和人工智能的今天和未来，孩子要与世界沟通，就必须学会与计算机.互联网.人工智能沟通的语言，这就是玩扣教育。</p>
        </el-dialog>
        <el-dialog title="教师版" :visible.sync="teacherVisible" width="400px" center :lock-scroll="false">
            <el-button type="text" @click="open" style="font-size:16px;margin: 0 auto;text-align: center">开通教师版会员
            </el-button>
            <br>
            <el-button type="text" style="font-size:16px;margin: 0 auto;text-align: center">教师版体验账号:teacher</el-button>
            <br>
            <el-button type="text" style="font-size:16px;margin: 0 auto;text-align: center">教师版体验密码:teacher</el-button>
        </el-dialog>
        <el-dialog title="加入我们" :visible.sync="joinUsVisible" width="400px" center :lock-scroll="false">
            <p style="font-size:16px;margin: 0 auto;">邮箱：wancode@163.com</p>
        </el-dialog>

        <el-dialog title="联系我们" :visible.sync="contactUsVisible" width="400px" center :lock-scroll="false">
            <p style="font-size:16px;margin: 0 auto;">邮箱：wancode@163.com</p>
            <br>
            <p style="font-size:16px;margin: 0 auto;">投稿邮箱：wancode@163.com</p>
            <br>
            <!--            <p style="font-size:16px;margin: 0 auto;">联系地址：上海市徐汇区田林路487号</p>-->
        </el-dialog>
        <el-dialog title="学习交流" :visible.sync="CourseConsultVisible" width="500px" center :lock-scroll="false">
            <p style="font-size:16px;margin: 0 auto;">课程顾问QQ: 630691351</p>
            <br>
            <p style="font-size:16px;margin: 0 auto;">课程顾问VX: wan_code</p>
            <br>
            <p style="font-size:16px;margin: 0 auto;">编程QQ学习交流群: 602969887 (验证消息：wancode)</p>
        </el-dialog>
        <el-dialog title="忘记密码" :visible.sync="passwordProfileVisible" width="400px" center :lock-scroll="false">
            <p style="font-size:16px;margin: 0 auto;">目前仅支持人工客服修改密码，请联系人工客服</p>
            <br>
            <p style="font-size:16px;margin: 0 auto;">QQ：630691351</p>
        </el-dialog>

        <el-dialog
                title="客服微信"
                :visible.sync="kfVisible"
                width="300px">
            <p>扫一扫 添加客服微信</p>
            <img src="@/assets/wechat.jpg" style="width: 250px;height:250px">
            <span slot="footer" class="dialog-footer">
                <el-button @click="kfVisible = false">取 消</el-button>
                <el-button type="primary" @click="kfVisible = false">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog
                title="网站功能使用手册"
                :visible.sync="useVisible"
                width="900px">
            <div class="use-style">
                <h2 style="margin-top: -30px;">一、试卷中心</h2>
                <p>试卷中心汇聚了历年各赛事、各校考试题目，您可以按照年份、赛事类型、学校等条件进行筛选查找。</p>
                <ul>
                    <li>
                        <strong>筛选操作：</strong>在试卷中心页面，通过左侧或上方的筛选栏，选择您需要的筛选条件，如选择年份为“2024”，赛事类型选择“数学竞赛”，点击“查询”按钮，即可展示符合条件的试卷。
                    </li>
                    <li>
                        <strong>试卷查看与下载：</strong>找到目标试卷后，点击试卷标题，可在线预览试卷内容；若您想下载试卷，点击“下载”按钮，选择保存路径即可。
                    </li>
                </ul>

                <h2>二、题库答题</h2>
                <p>题库答题功能可用于搜题和刷题，助力您提升知识水平。</p>
                <ul>
                    <li>
                        <strong>搜题：</strong>在题库答题页面的搜索框中，输入题目关键词，如数学题中的公式、物理题中的关键条件等，点击“搜索”，系统会快速为您展示相关题目及答案解析。
                    </li>
                    <li>
                        <strong>刷题：</strong>点击“刷题”按钮，您可以选择不同的刷题模式，如章节练习、模拟考试等。在章节练习中，选择您想要练习的学科章节，开始答题；模拟考试则会按照考试的题型和时间要求，为您提供模拟考试环境。答题过程中，系统会实时显示您的答题进度和得分情况。
                    </li>
                </ul>

                <h2>三、考试记录</h2>
                <p>考试记录功能详细记录了您每次考试的答题结果，方便您回顾和分析。</p>
                <ul>
                    <li>
                        <strong>记录查看：</strong>在考试记录页面，您可以看到所有参加过的考试列表，包括考试名称、考试时间、得分等信息。点击具体某条考试记录，可查看详细的答题情况，包括每道题的作答内容、正确与否以及答案解析。
                    </li>
                    <li>
                        <strong>分析总结：</strong>通过查看考试记录，您可以总结自己的薄弱知识点，针对性地进行复习和强化训练。
                    </li>
                </ul>

                <h2>四、错题本</h2>
                <p>错题本帮助您收藏答错的题目，便于集中复习。</p>
                <ul>
                    <li>
                        <strong>题目收藏：</strong>在答题过程中，当您回答错误时，系统会自动提示是否将该题加入错题本，点击“确定”即可收藏；您也可以在考试记录或题库答题页面，找到错题，手动点击“加入错题本”。
                    </li>
                    <li>
                        <strong>错题复习：</strong>进入错题本页面，可看到所有收藏的错题。您可以按照学科、知识点等分类查看错题，点击错题可再次作答，并查看详细的答案解析和解题思路。还可以将错题导出，打印出来进行线下复习。
                    </li>
                </ul>

                <h2>五、教师中心</h2>
                <p>教师中心是专门为教师账号设置的功能模块，包含学生管理、布置作业、记录课堂等功能。</p>
                <ul>
                    <li>
                        <strong>学生管理：</strong>教师可以在此查看所教班级学生的基本信息、学习成绩等，还能对学生进行分组管理，方便教学活动的开展。点击“学生列表”，可查看所有学生名单，点击具体学生姓名，可查看该学生的详细学习情况。
                    </li>
                    <li>
                        <strong>布置作业：</strong>在布置作业板块，教师可以选择作业类型（如书面作业、在线作业等），设置作业截止时间，添加作业内容（可上传文档、图片等附件），然后选择要布置的班级和学生，点击“发布”即可。
                    </li>
                    <li>
                        <strong>记录课堂：</strong>教师可以记录课堂上的重点内容、学生表现等，方便后续教学参考和总结。点击“课堂记录”，选择对应的课程和日期，输入记录内容并保存。
                    </li>
                </ul>

                <h2>六、会员中心</h2>
                <p>网站部分试卷题目仅限VIP账号使用，您可以在会员中心开通会员。</p>
                <ul>
                    <li>
                        <strong>会员开通：</strong>在会员中心页面，选择您想要开通的会员套餐（如月会员、季会员、年会员等），点击“立即开通”，按照系统提示选择支付方式（如微信支付、支付宝支付等），完成支付后即可开通会员。
                    </li>
                    <li>
                        <strong>会员权益查看：</strong>开通会员后，在会员中心可查看会员权益，如可下载的试卷数量、专属的会员题库等。
                    </li>
                </ul>

                <h2>七、其它</h2>
                <p>如果您在使用网站过程中，对更多功能不知如何操作，可随时联系客服。在网站页面的底部，点击“联系客服”按钮，我们将竭诚为您服务。</p>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="useVisible = false">取 消</el-button>
                <el-button type="primary" @click="useVisible = false">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog title="意见反馈" :visible.sync="feedBackVisible" width="500px">

            <el-form :model="feedBackForm" :rules="rules" ref="feedBackForm">
                <el-form-item prop="feedbackContent">
                    <el-input v-model="feedBackForm.feedbackContent"
                              type="textarea"
                              :rows="6"
                              placeholder="请输入您的宝贵建议"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="feedBackVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitFeedback">确 定</el-button>
            </div>
        </el-dialog>
    </div>

</template>
<script>
    import homeTitle from "@/components/homeTitle.vue";
    import homeBottom from "@/components/homeBottom.vue";
    import loginDialog from "@/components/loginDialog.vue";
    import rightBlock from "@/components/rightBlock.vue";
    import testPaperDetails from "@/views/testPaperDetails/index.vue"

    import {
        addFeedback,
    } from '@/api/index.js'

    export default {
        components: {
            homeTitle,
            homeBottom,
            loginDialog,
            testPaperDetails,
            rightBlock
        },
        watch: {
            $route(to, from) {
                this.path = to.path;
            }
        },
        data() {
            return {
                loginDialogShow: false,
                loginSuccessShow: false,
                path: "",
                exemptionDialogVisible: false,
                contactUsVisible: false,
                CourseConsultVisible: false,
                joinUsVisible: false,
                teacherVisible: false,
                kfVisible: false,
                useVisible: false,
                feedBackVisible: false,
                companyProfileVisible: false,
                passwordProfileVisible: false,
                feedBackForm: {
                    feedbackContent: ''
                },
                isBottomLoaded: false,
                rules: {
                    feedbackContent: [
                        {required: true, message: '建议反馈不能为空！', trigger: 'blur'}
                    ]
                }
            };
        },
        mounted() {
            this.path = this.$route.path;

            window.showLogin = this.showDialog;
            // 设置延迟时间，这里设置为 2000 毫秒（即 2 秒）
            setTimeout(() => {
                // 延迟时间到后，将标志设置为 true，渲染 homeBottom 组件
                this.isBottomLoaded = true;
            }, 100);
            // if (localStorage.getItem("token")) {
            //   this.loginDialogShow = false
            // } else {
            //   this.loginDialogShow = true
            // }
        },
        methods: {
            clickBottomItem(item) {
                // console.log(item)
                if (item === "公司简介") {
                    this.companyProfileVisible = true
                }
                if (item === "教师版") {
                    this.teacherVisible = true
                }
                if (item === "联系我们") {
                    this.contactUsVisible = true
                }
                if (item === "学习交流") {
                    this.CourseConsultVisible = true
                }
                if (item === "免责声明") {
                    this.exemptionDialogVisible = true
                }
                if (item === "考试报名") {
                    window.open("https://www.qceit.org.cn/bos/default.html");
                }
                if (item === "忘记密码") {
                    this.passwordProfileVisible = true
                }
                if (item === "客服微信") {
                    this.kfVisible = true
                }
                if (item === "使用手册") {
                    this.useVisible = true
                }
                if (item === "意见反馈") {
                    this.feedBackVisible = true;
                }

                if (item === "加入我们") {
                    this.joinUsVisible = true
                } else if (item === "隐私协议") {
                    window.open("/programmingPrivacy.html");                 //在另外新建窗口中打开窗口
                } else if (item === "用户协议") {
                    window.open("/serviceAgreement.html");                 //在另外新建窗口中打开窗口
                }
            },
            open() {
                this.$router.push({name: 'selfVIP', params: {teacherPrise: "1"}});
                this.teacherVisible = false
                window.scrollTo(0, 0);
            },
            clickLogin() {
                this.loginDialogShow = true
            },

            // clearSession() {
            //   localStorage.setItem("token", "");
            // },
            closeDialog() {
                this.isHidden = true;
                setTimeout(() => {
                    this.loginDialogShow = false
                })
            },
            showDialog() {
                this.loginDialogShow = true
                // console.log(this.loginDialogShow)
            },
            loginSuccess() {
                // this.loginSuccessShow = true
            },
            submitFeedback() {
                this.feedBackVisible = false;
                this.$refs.feedBackForm.validate((valid) => {
                    if (valid) {
                        this.$message.success("提交成功！");
                        addFeedback(this.feedBackForm).then(res => {
                        });
                        this.feedBackForm.feedbackContent = ''
                    }
                });
            },
        }
    };
</script>

<style lang="scss">

    /*.el-dialog__body {*/
    /*  height: 55vh;*/
    /*  overflow: auto;*/
    /*}*/

    .page {
        width: 1200px;
        margin: 0 auto;
        text-align: left;
        //padding: 0 100px;
    }

    .homeTitleStyle {
        position: fixed;
        top: 0;
        z-index: 999;
    }

    .routerViewStyle {
        padding-top: 60px;
        margin-bottom: 20px;
        min-height: 700px;
    }


    body,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    hr,
    p,
    blockquote,
    dl,
    dt,
    dd,
    ul,
    ol,
    li,
    pre,
    form,
    fieldset,
    legend,
    button,
    input,
    textarea,
    th,
    td {
        margin: 0;
        padding: 0;
    }

    body,
    button,
    input,
    select,
    textarea {
        font: 12px/1.5tahoma, arial, \5b8b\4f53;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-size: 100%;
    }

    address,
    cite,
    dfn,
    em,
    var {
        font-style: normal;
    }

    code,
    kbd,
    pre,
    samp {
        font-family: couriernew, courier, monospace;
    }

    small {
        font-size: 12px;
    }

    ul,
    ol {
        list-style: none;
    }

    a {
        text-decoration: none;
    }

    a:hover {
        text-decoration: underline;
    }

    sup {
        vertical-align: text-top;
    }

    sub {
        vertical-align: text-bottom;
    }

    legend {
        color: #000;
    }

    fieldset,
    img {
        border: 0;
    }

    button,
    input,
    select,
    textarea {
        font-size: 100%;
    }

    table {
        border-collapse: collapse;
        border-spacing: 0;
    }

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        //min-width: 1480px;
        /*background-color: #F5F5F5;*/
        background-color: #EFEFEF;
        //overflow: hidden;
    }

    .use-style{
        /*font-family: Arial, sans-serif;*/
        line-height: 1.8;
        margin: 20px;
        text-align: left;

        h2 {
            margin-top: 20px;
            /*border-bottom: 1px solid #ccc;*/
            padding-bottom: 5px;
        }
        ul {
            list-style-type: none;
            padding: 0;
        }
        li {
            margin-bottom: 10px;
        }
        strong {
            display: inline-block;
            /*width: 120px;*/
        }
    }
</style>

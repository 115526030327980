import request from '@/utils/request.js';

//登录
export function login(data) {
    return request({
        url: '/wancode/exam/user/login',
        method: 'post',
        data
    })
}

//登录
export function register(data) {
    return request({
        url: '/wancode/exam/user/register',
        method: 'post',
        data
    })
}

//登出
export function logoutSystem(data) {
    return request({
        url: '/wancode/exam/user/logout',
        method: 'post',
        data
    })
}

//首页消息
export function homeNotice(data) {
    return request({
        url: '/wancode/exam/home/notice/new',
        method: 'post',
        data
    })
}

//首页试卷
export function homeTitle(data) {
    return request({
        url: '/wancode/exam/home/title',
        method: 'get',
        data
    })
}

//首页试卷
export function homePaper(data) {
    return request({
        url: '/wancode/exam/home/index',
        method: 'get',
        data
    })
}

//试卷中心获取试卷
export function getPaperList(data) {
    return request({
        url: '/wancode/exam/paper/pageList',
        method: 'post',
        data
    })
}

//试卷中心-搜索试卷
export function getPaperByName(data) {
    return request({
        url: '/wancode/exam/paper/getPaperByName',
        method: 'post',
        data
    })
}

//试卷详情by id
export function getPaperDetails(id, data) {
    return request({
        url: `/wancode/exam/paper/select/${id}`,
        method: 'post',
        data
    })
}

//获取用户每日答题次数
export function checkCountByAnswerPaperAndDay(paperId) {
    return request({
        url: `/wancode/exam/paper/check/${paperId}`,
        method: 'post'
    })
}

//提交试卷
export function submitPaperAnswer(data) {
    return request({
        url: "/wancode/exam/answer/submit",
        method: 'post',
        data
    })
}

//考试记录
export function examinationRecord(data) {
    return request({
        url: "/wancode/exam/answer/pageList",
        method: 'post',
        data
    })
}

//考试记录详情
export function examinationRecordDetails(data) {
    return request({
        url: "/wancode/exam/answer/read/" + data.id,
        method: 'post',
        data
    })
}

//题库列表
export function questionList(data) {
    return request({
        url: "/wancode/exam/question/pageList",
        method: 'post',
        data
    })
}

//题库-答题列表
export function userAnswerList(data) {
    return request({
        url: "/wancode/exam/question/userAnswerList",
        method: 'post',
        data
    })
}

//获取公告信息
export function getNoticeInfo(data) {
    return request({
        url: "/wancode/exam/notice/list",
        method: 'post',
        data
    })
}

//个人信息
export function selfInfo(data) {
    return request({
        url: "/wancode/exam/user/info",
        method: 'post',
        data
    })
}

//修改个人信息
export function changeSelfInfo(data) {
    return request({
        url: "/wancode/exam/user/update",
        method: 'post',
        data
    })
}

//修改密码
export function changePassword(data) {
    return request({
        url: "/wancode/exam/user/updatePassword",
        method: 'post',
        data
    })
}

//获取日志
export function getSelfLog(data) {
    return request({
        url: "/wancode/exam/user/log",
        method: 'post',
        data
    })
}

//我的课程
export function classDetails() {
    return request({
        url: "/wancode/exam/user/class",
        method: 'post'
    })
}

//积分明细
export function pointDetails(data) {
    return request({
        url: "/wancode/exam/user/integral",
        method: 'post',
        data
    })
}

//获取个人消息
export function getUserNotice(data) {
    return request({
        url: "/wancode/exam/user/notice/list",
        method: 'post',
        data
    })
}

export function read(id) {
    return request({
        url: `/wancode/exam/user/detail/${id}`,
        method: 'post'
    })
}

//下载中心
export function downloadCenter(data) {
    return request({
        url: "/wancode/exam/file/server/download",
        method: 'get',
        data
    })
}

//获取错题集
export function wrongBookList(data) {
    return request({
        url: "/wancode/exam/question/answer/page",
        method: 'post',
        data
    })
}

//错题集详情
export function wrongBookDetails(data) {
    return request({
        url: `/wancode/exam/question/answer/select/${data.id}`,
        method: 'post',
        data
    })
}

//删除错题
export function deleteWrongBookQuestion(id) {
    return request({
        url: `/wancode/exam/question/answer/delete/${id}`,
        method: 'post'
    })
}

//题目详情
export function getQuestionDetail(id) {
    return request({
        url: `/wancode/exam/question/select/${id}`,
        method: 'post'
    })
}

//题目解析
export function getQuestionAnalysis(id) {
    return request({
        url: `/wancode/exam/question/analysis/${id}`,
        method: 'post'
    })
}

//题库-答题
export function submitQuestionAnswer(data) {
    return request({
        url: "/wancode/exam/question/submit",
        method: 'post',
        data
    })
}

//题库-答题
export function countByDaySubmit() {
    return request({
        url: "/wancode/exam/question/countByDaySubmit",
        method: 'post'
    })
}


//选课列表
export function selectCourseList(data) {
    return request({
        url: `/wancode/exam/class/pageList`,
        method: 'post',
        data
    })
}

//提交选课
export function applyCourseList(data) {
    return request({
        url: `/wancode/exam/class/apply`,
        method: 'post',
        data
    })
}

//下载软件
export function downLoadSoft(data) {
    return request({
        url: `/wancode/exam/file/server/download?type=${data.type}`,
        method: 'get',
    })
}

//开通VIP
export function openVip(data) {
    return request({
        url: `/wancode/exam/user/openVip/`,
        method: 'post',
        data
    })
}

//开通教师版VIP
export function openTeacherVip(data) {
    return request({
        url: `/wancode/exam/user/openTeacherVip/`,
        method: 'post',
        data
    })
}

export function noticeDetails(data) {
    return request({
        url: `/wancode/exam/notice/detail/${data.id}`,
        method: 'post',
        data
    })
}

//首页统计
export function homePageInfo(data) {
    return request({
        url: `/wancode/exam/home/data/info`,
        method: 'post',
        data
    })
}

//修改课堂记录
export function editClass(data) {
    return request({
        url: `/wancode/exam/user/class/edit`,
        method: 'post',
        data
    })
}

export function deleteUserClass(data) {
    return request({
        url: `/wancode/exam/user/class/delete/${data.id}`,
        method: 'post',
        data
    })
}

//代理商登录
export function agentLogin(data) {
    return request({
        url: '/wancode/agent/user/login',
        method: 'post',
        data
    })
}

//注册
export function agentRegister(data) {
    return request({
        url: '/wancode/exam/user/userAdd',
        method: 'post',
        data
    })
}

//人工评分
export function updateScore(data) {
    return request({
        url: '/wancode/exam/answer/updateScore',
        method: 'post',
        data
    })
}

export function updateUser(data) {
    return request({
        url: '/wancode/exam/user/update',
        method: 'post',
        data
    })
}


export function getStudentInfo2(data) {
    return request({
        url: '/wancode/exam/user/studentTwo',
        method: 'post',
        data
    })
}

export function resetPassword(data) {
    return request({
        url: '/wancode/exam/user/resetPassword',
        method: 'post',
        data
    })
}

//注销学员账号
export function cancelLoginAccount(data) {
    return request({
        url: '/wancode/exam/user/cancelLoginAccount',
        method: 'post',
        data
    })
}

//试卷分析
export function getPaperListTwo(data) {
    return request({
        url: '/wancode/exam/paper/pageListTwo',
        method: 'post',
        data
    })
}

export function getStudentInfo(data) {
    return request({
        url: '/wancode/exam/user/student',
        method: 'post',
        data
    })
}

//PDF下载
export function toDownload(id) {
    return request({
        //生产环境改成toDownload
        //测试环境改成toDownload2
        url: `/wancode/exam/pdf/toDownload/${id}`,
        method: 'get',
        responseType: 'blob'
    })
}

//WORD下载
export function toDownloadWord(id) {
    return request({
        url: `/wancode/exam/pdf/downloadWord/${id}`,
        method: 'get',
        responseType: 'blob'
    })
}

export function toDownloadAnswer(id) {
    return request({
        url: `/wancode/exam/pdf/toDownloadAnswer/${id}`,
        method: 'get',
        responseType: 'blob'
    })
}

//获取用户PDF下载次数
export function paperDownCount(id) {
    return request({
        url: `/wancode/exam/pdf/toDownload/check/${id}`,
        method: 'post',
    })
}


//布置作业
export function assignment(data) {
    return request({
        url: '/wancode/exam/teacher/assignment',
        method: 'post',
        data
    })
}

//试卷分析
export function getMyWork(data) {
    return request({
        url: '/wancode/exam/user/work',
        method: 'post',
        data
    })
}

//提交纠错细心
export function addAmend(data) {
    return request({
        url: '/wancode/exam/amend/add',
        method: 'post',
        data
    })
}

//提交解析补充
export function addAnalysis(data) {
    return request({
        url: '/wancode/exam/analysis/add',
        method: 'post',
        data
    })
}


//提交建议
export function addFeedback(data) {
    return request({
        url: '/wancode/exam/feedback/add',
        method: 'post',
        data
    })
}

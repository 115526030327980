<template>
    <div>
        <div>
            <el-card class="box-card">
                <div slot="header" class="subjectTitle">
                    <div style="display: flex;" class="subjectName">
                        <span style="font-size: 18px;" v-if="subjectId !=null">{{subjectId}}.</span>
                        <div class="editor-content-view">
                            <div v-html="questionBody.title" style="font-size: 18px;" v-safe></div>
                        </div>
                    </div>
                </div>

                <p v-if="haveAnswer" class="content" style="padding-bottom: 10px"><span>我的答案:</span></p>
                <div>
                    <editor ref="pyEditer" v-model="myAnswer" @init="editorInit" lang="python" theme="eclipse"
                            width="100%" height="400px" :options="{
					   enableBasicAutocompletion: true,
					   enableSnippets: true,
					   enableLiveAutocompletion: true,
					   tabSize:2,
					   fontSize:16,
					   showPrintMargin:false
					 }"></editor>
                </div>

                <div v-if="haveAnswer" class="answer-style">

                    <!--					<p class="content"><span>标准答案:</span><span v-html="questionBody.correct"></span></p>-->
                    <div style="display: flex;">
                        <span class="content"><span>结果: </span></span>
                        <el-tag size="mini" v-if="questionBody.answerInfo.doRight" type="success"
                                style="margin-top: 20px;">
                            正确
                        </el-tag>
                        <el-tag size="mini" v-if="!questionBody.answerInfo.doRight" type="danger"
                                style="margin-top: 20px;">
                            错误
                        </el-tag>
                    </div>
                    <p class="content">
                        <span>得分:  </span>{{questionBody.answerInfo.score}} / {{questionBody.score}}
                        <span style="color: red;margin-left: 10px">
                         （提示：编程题属于主观题范畴，在当前的评价体系中，其默认比重为60%。）
                        </span>
                    </p>

                    <p class="content">
                        <span>得分率:</span>60%
                    </p>
                    <div style="display: flex;">
                        <p class="content"><span>难度:  </span></p>
                        <el-rate v-model="questionBody.difficult" style="margin-top: 20px;" disabled=""></el-rate>
                    </div>
                    <div>
                        <p class="content" style="padding-bottom: 10px"><span>参考答案:</span></p>
                        <editor ref="pyEditer" v-model="content" @init="editorInit" lang="python" theme="eclipse"
                                width="100%" height="400px" :options="{
					   enableBasicAutocompletion: true,
					   enableSnippets: true,
					   enableLiveAutocompletion: true,
					   tabSize:2,
					   fontSize:17,
					   showPrintMargin:false
					 }"></editor>
                    </div>
                    <p class="content"><span>解析:</span><span v-html="questionBody.analyze"></span></p>

                    <div class="cardButton">
                        <span @click="openAnalysisDialog()" style="color:red">解析补充</span>
                        <span @click="openAmendDialog()" style="color:red">纠错</span>
                        <span @click="collectQuestion()" style="color:green">收藏</span>
                    </div>
                </div>
            </el-card>
        </div>
        <amendDialog :amendDialogShow="amendDialogShow"
                     :questionId="this.questionBody.id"
                     @closeAmendDialog="closeAmendDialog">
        </amendDialog>

        <analysisDialog :analysisDialogShow="analysisDialogShow" :questionId="this.questionBody.id"
                        @closeAnalysisDialog="closeAnalysisDialog"></analysisDialog>
    </div>
</template>

<script>
    import glbJson from '../utils/global_variable.js'
    import amendDialog from "@/components/amendDialog.vue"
    import analysisDialog from "@/components/analysisDialog.vue"

    export default {
        components: {
            analysisDialog,
            amendDialog,
            editor: require('vue2-ace-editor')
        },
        props: {
            subjectId: {
                type: Number,
                default: 1
            },
            isDis: {
                type: Boolean,
                default: false
            },
            questionBody: {
                type: Object,
                default: () => ({})
            },
            haveAnswer: {
                type: Boolean,
                default: false
            },
            chooseItem: {
                type: String,
                default: ""
            }
        },
        mounted() {
            if (this.haveAnswer) {
                // console.log(this.questionBody)
                // this.questionBody.items.forEach((item, index) => {
                // 	this.content = this.chooseItem
                // this.content = this.questionBody.answerInfo.content

                this.content = this.questionBody.correct
                this.myAnswer = this.questionBody.answerInfo.content
                // })
            } else {
                if (this.questionBody.langCode === 'Scratch') {
                    this.myAnswer = 'Scratch编程题目前暂不支持代码上传，现只需自己电脑答题即可'
                }
            }
        },
        methods: {
            editorInit: function () {
                //初始化ace
                require("brace/ext/language_tools");
                require("brace/mode/python");
                require("brace/snippets/python");
                require("brace/theme/eclipse");
            },
            openAmendDialog() {
                this.amendDialogShow = true;
            },
            closeAmendDialog() {
                this.amendDialogShow = false;
            },
            openAnalysisDialog() {
                this.analysisDialogShow = true;
            },
            closeAnalysisDialog() {
                this.analysisDialogShow = false;
            },
            collectQuestion() {
                this.$message({
                    type: 'success',
                    message: '收藏成功！'
                });
            }
        },
        watch: {
            myAnswer: {

                handler: function (n, o) {
                    if (this.haveAnswer) {

                    } else {
                        if (this.myAnswer == "") {
                            this.$emit("unchanged", {
                                id: this.subjectId,
                                name: this.myAnswer
                            })
                        } else {
                            this.$emit("changed", {
                                id: this.subjectId,
                                name: this.radio,
                                questionId: this.questionBody.id,
                                content: this.myAnswer,
                                contentArray: [],
                                itemOrder: this.subjectId
                            })
                        }
                    }
                },
                deep: true
            },
            content: {

                handler: function (n, o) {
                    if (this.haveAnswer) {

                    } else {
                        // console.log(this.content)
                        if (this.content == "") {
                            this.$emit("unchanged", {
                                id: this.subjectId,
                                name: this.content
                            })
                        } else {
                            this.$emit("changed", {
                                id: this.subjectId,
                                name: this.radio,
                                questionId: this.questionBody.id,
                                content: this.content,
                                contentArray: [],
                                itemOrder: this.subjectId
                            })
                        }
                    }
                },
                deep: true
            }
        },
        data() {
            return {
                textarea: "",
                content: "",
                myAnswer: "",
            }
        }
    }
</script>
<style>

</style>

<style lang="scss" scoped>

    :deep(.editor-content-view pre) {
        word-wrap: normal;
        font-family: Consolas, Monaco, Andale Mono, Ubuntu Mono, monospace;
        background-color: #F5F2F0;
        /*border: 3px solid #ccc;*/
        border-radius: 5px;
        padding: 10px 10px;
        margin-top: 5px;
        /*margin-bottom: 10px;*/
        overflow-x: auto;
        font-size: 16px;
        line-height: 1.5;
        min-width: 750px;
        tab-size: 4;

        br {
            display: none;
        }
    }

    :deep(.editor-content-view pre) {
        /*font-family: monospace;*/
        /*background-color: #eee;*/
        /*padding: 5px;*/
        /*border-radius: 3px;*/
    }

    /* 确保sub和sup标签被正确显示 */
    ::v-deep sub {
        vertical-align: sub;
        font-size: smaller;
    }

    ::v-deep sup {
        vertical-align: super;
        font-size: smaller;
    }


    .subjectName {
        display: flex;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        line-height: 36px;

        p {
            font-size: 16px;
        }
    }


    .content {
        font-size: 14px;
        margin-top: 20px;
        color: #808080;
        display: flex;

        span {
            font-weight: 600;
            color: #000;
            line-height: 20px;
        }
    }

    .answer-style {
        position: relative;
        border: 1px solid #eee;
        border-radius: 5px;
        line-height: 24px;
        margin: 20px 0 10px 0;
        padding-left: 15px;
        font-size: 15px;
        color: #555;
        background: #fcfcfc;

        .cardButton {
            position: absolute;
            right: 10px;
            top: 10px;

            span {
                padding-top: 10px;
                padding-left: 10px;
                padding-right: 10px;
                /*color: #FFFFFF;*/

                &:hover {
                    cursor: pointer;
                }
            }
        }

    }
</style>

import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [{
    path: "/",
    name: "homePage",
    component: () => import('@/views/homePage/index')
}, {
    path: "/testPaperCenter",
    name: "testPaperCenter",
    component: () => import('@/views/testPaperCenter/index')
}, {
    path: "/examinationRecord",
    name: "examinationRecord",
    component: () => import('@/views/examinationRecord/index')
}, {
    path: "/questionBank",
    name: "questionBank",
    component: () => import('@/views/questionBank/index')
}, {
    path: "/wrongBook",
    name: "wrongBook",
    component: () => import('@/views/wrongBook/index')
}, {
    path: "/teacherManage",
    name: "teacherManage",
    component: () => import('@/views/teacherManage/index')
}, {
    path: "/myWork",
    name: "myWork",
    component: () => import('@/views/myWork/index')
}, {
    path: "/selfInfo",
    name: "selfInfo",
    component: () => import('@/views/selfInfo/index')
}, {
    path: "/selfVIP",
    name: "selfVIP",
    component: () => import('@/views/selfVIP/index')
},
    {
        path: "/courseLearning",
        name: "courseLearning",
        component: () => import('@/views/courseLearning/index')
    },
    {
        path: "/shoppingMall",
        name: "shoppingMall",
        component: () => import('@/views/shoppingMall/index')
    },
    {
        path: "/notice",
        name: "notice",
        component: () => import('@/views/notice/index')
    },
    {
        path: "/noticeDetails",
        name: "noticeDetails",
        component: () => import('@/views/notice/noticeDetails')
    },
    {
        path: "/downLoadCenter",
        name: "downLoadCenter",
        component: () => import('@/views/downLoadCenter/index')
    },
    {
        path: "/testPaperDetails",
        name: "testPaperDetails",
        component: () => import('@/views/testPaperDetails/index')
    },
    {
        path: "/viewTestPaper",
        name: "viewTestPaper",
        component: () => import('@/views/viewTestPaper/index')
    }, {
        path: "/courseSelectionCenter",
        name: "courseSelectionCenter",
        component: () => import('@/views/courseSelectionCenter/index')
    }, {
        path: "/questionDetail",
        name: "questionDetail",
        component: () => import('@/views/questionBank/components/questionDetail')
    }, {
        path: "/questionAnswerDetail",
        name: "questionAnswerDetail",
        component: () => import('@/views/questionBank/components/questionAnswerDetail')
    }
];

const router = new VueRouter({
    // mode: "history",
    base: process.env.BASE_URL,
    routes
});

export default router;

<template>
    <div>
        <!--		<div style="width: 50px;height: 100%;background-color: #FFFFFF;position: relative;box-shadow: -10px -10px 10px #B3C0D1;z-index: 999;">-->
        <div style="width: 50px;position: fixed; right: 50px;bottom: 100px;">
            <el-popover placement="left" width="200" trigger="click">
                <div style="width: 100%;text-align: center;border-bottom: 1px solid #f5f5f5;">
                    <p style="line-height: 30px;font-size: 18px;font-weight: 900;color: #000000;">咨询客服</p>
                </div>
                <div>
                    <p style="line-height: 30px;font-size: 18px;font-weight: 700;color: #000000;">在线客服</p>
                    <p style="line-height: 30px;font-size: 16px"><span style="color: #000000;">工作日：</span><span
                            style="color:#A9A9A9;">10:00 ~ 17:00</span></p>
                    <p style="line-height: 30px;font-size: 16px"><span style="color: #000000;">节假日：</span><span
                            style="color:#A9A9A9;">11:30 ~ 16:00</span></p>
                    <p style="line-height: 30px;font-size: 14px;color:#A9A9A9;">非工作时间欢迎在线留言，客服上线将第一时间进行处理</p>
                </div>
                <div style="margin: 10px auto;text-align: center;">
                    <a target="_blank" href="http://wpa.qq.com/msgrd?v=3&uin=630691351&site=qq&menu=yes">
                        <el-button type="primary" size="mini">点击联系客服</el-button>
                    </a>
                </div>
                <div slot="reference"
                     style="width: 50px;height: 50px;position: absolute;top: 50%;margin-top: -25px;background-color: #409EFF;">
                    <i class="el-icon-headset" style="color: #FFFFFF;padding: 5px;"></i>
                    <p style="color: #FFFFFF;">客服</p>
                </div>
            </el-popover>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                dialogVisible: false
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>

<template>
    <div id="scrollDiv" style="min-height: 1000px" v-loading="loading" element-loading-text="提交中....">
        <div class="titleStyle">
            <p>
                {{paperTitle}}
            </p>
        </div>

        <div v-for="(item,index) in allQuestionArr" :key="index">
            <div class="titleType">
                <p>
                    {{item.name}}
                </p>
            </div>
            <div style="padding-bottom: 10px;" v-for="(item1,index1) in item.questionItems"
                 :key="index1">
                <a :id="'q' + item1.itemOrder">
                    <radioSubject v-if="(item1.questionType === 0) || (item1.questionType === 1) "
                                  :subjectId="item1.itemOrder" @changed="changed" @unchanged="unchanged"
                                  :questionBody="item1">
                    </radioSubject>
                    <checkSubject v-if="item1.questionType === 2" :subjectId="item1.itemOrder" @changed="changed"
                                  @unchanged="unchanged" :questionBody="item1"></checkSubject>
                    <programmingSubject v-if="item1.questionType === 3" :subjectId="item1.itemOrder" @changed="changed"
                                        @unchanged="unchanged" :questionBody="item1">
                    </programmingSubject>
                    <programmingSubject v-if="item1.questionType === 4" :subjectId="item1.itemOrder" @changed="changed"
                                        @unchanged="unchanged" :questionBody="item1">
                    </programmingSubject>
                    <programmingSubjectMore v-if="item1.questionType === 5" :subjectId="item1.itemOrder"
                                            @changed="changed"
                                            @unchanged="unchanged" :questionBody="item1">
                    </programmingSubjectMore>
                </a>
            </div>
            <el-divider></el-divider>
        </div>
    </div>
</template>

<script>
    import radioSubject from "@/components/radioSubject.vue"
    import checkSubject from "@/components/checkSubject.vue"
    import shortAnswerSubject from "@/components/shortAnswerSubject.vue"
    import programmingSubject from "@/components/programmingSubject.vue"
    import programmingSubjectMore from "@/components/programmingSubjectMore.vue"
    import {
        submitPaperAnswer
    } from "@/api/index.js"

    export default {
        props: {
            allQuestionArr: {
                type: Array,
                default: () => [{}]
            },
            paperTitle: {
                type: String,
                default: '试卷标题'
            },
            //用于快速定位的题目位置
            maodian: {
                type: String,
                default: '#1'
            },
        },
        //禁止复制
        created() {
            this.$nextTick(() => {
                // 禁用右键
                document.oncontextmenu = new Function("event.returnValue=false");
                // 禁用选择
                document.onselectstart = new Function("event.returnValue=false");
            });
        },
        watch: {
            allQuestionArr: {
                handler: function (n, o) {
                    this.allQuestionArr.forEach((item) => {
                        item.questionItems.forEach((item1) => {
                            if (item1.questionType == 5) {
                                let tmpEnter = [];
                                for (let i = 0; i < item1.enterNum; i++) {
                                    // Things[i]
                                    let tmp = {
                                        id: i + 1,
                                        value: ""
                                    }
                                    tmpEnter.push(tmp)
                                }
                                item1.tmpEnter = tmpEnter
                            }
                        })
                    })
                }
            },
            isAnswer: {
                handler: function (n, o) {
                    // console.log(this.isAnswer);
                    if (this.isAnswer.length != 0) {
                        this.$emit("isAnswerArr", this.isAnswer)
                    }
                }
            },
            maodian: {
                handler: function (n, o) {
                    var target = document.querySelector(this.maodian);
                    this.scrollAnimation(document.getElementById("scrollDiv").parentNode.scrollTop, (target.offsetTop -
                        40))
                },
                deep: true
            }
        },
        components: {
            radioSubject,
            checkSubject,
            shortAnswerSubject,
            programmingSubject,
            programmingSubjectMore
        },
        data() {
            return {
                isAnswer: [],
                loading: false,
            }
        },
        methods: {
            scrollAnimation(currentY, targetY) {
                // 计算需要移动的距离
                let needScrollTop = targetY - currentY - 22
                let _currentY = currentY
                console.log(_currentY)
                setTimeout(() => {
                    // 一次调用滑动帧数，每次调用会不一样
                    const dist = Math.ceil(needScrollTop / 10)
                    _currentY += dist
                    // document.getElementById("scrollDiv").parentNode.scrollTo(_currentY, currentY);
                    window.scrollTo(_currentY, currentY);
                    // 如果移动幅度小于十个像素，直接移动，否则递归调用，实现动画效果
                    if (needScrollTop > 10 || needScrollTop < -10) {
                        this.scrollAnimation(_currentY, targetY)
                    } else {
                        window.scrollTo(0, _currentY);
                        // document.getElementById("scrollDiv").parentNode.parentNode.parentNode.scrollTo(0, _currentY)
                    }
                }, 1)
            },
            changed(item) {
                if (this.isAnswer.length == 0) {
                    this.isAnswer.push(item)
                } else {
                    let flag = 0
                    this.isAnswer.forEach((item1) => {
                        if (item1.id == item.id) {
                            flag = 1
                            item1.content = item.content
                            item1.contentArray = item.contentArray
                        } else {
                        }
                    })
                    if (flag == 0) {
                        this.isAnswer.push(item)
                    }
                }
            },
            unchanged(item) {
                this.isAnswer.filter((item1, index) => {
                    if (item1.id == item.id) {
                        this.isAnswer.splice(index, 1)
                    }
                })
            },
            submitSubject(id, time) {
                this.$confirm('是否确定提交试卷？', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.loading = true;
                    let param = {
                        id: id,
                        doTime: Number(time),
                        answerItems: this.isAnswer
                    }
                    submitPaperAnswer(param).then(res => {
                        // console.log(res)
                        this.loading = false;
                        if (res.code === "0000") {
                            let score = null
                            if (res.data) {
                                score = res.data.score
                            } else {
                                score = 0
                            }
                            this.$confirm(`您本次的得分为${score}分`, '提示', {
                                // confirmButtonText: '确定',
                                cancelButtonText: '关闭',
                                confirmButtonText: '答案解析',
                                type: 'warning',
                            }).then(() => {
                                let jumpRouter = this.$router.resolve({
                                    path: "/viewTestPaper?id=" + res.data.id
                                })
                                window.open(jumpRouter.href, '_blank');
                                window.close()
                            }).catch(() => {
                                this.$router.push({
                                    name: "testPaperCenter"
                                })
                            });

                        }
                    })

                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消提交'
                    });
                });
            }
        }
    }
</script>

<style lang="scss" scoped>
    ::v-deep .el-card__header {
        background-color: #FFFFFF;
        border: none;
    }

    //  ::v-deep.el-card.is-always-shadow, .el-card.is-hover-shadow:focus, .el-card.is-hover-shadow:hover{
    // 	box-shadow: none!important;
    // }

    a {
        text-decoration: none !important;
    }


    .titleStyle {
        width: 100%;
        height: 50px;
        //background-color: #889AA4;
        border-radius: 10px;

        margin-bottom: 30px;
        margin-top: 10px;

        p {
            font-size: 22px;
            //font-weight: 500;
            line-height: 50px;
            color: #303133;
            text-align: center;
            border-radius: 10px;
        }
    }

    .titleType {
        width: 100%;
        height: 50px;
        //background-color: #889AA4;
        background-color: #F5F5F5;
        border-radius: 10px;

        p {
            font-size: 22px;
            //font-weight: 500;
            line-height: 50px;
            color: #303133;
            text-align: center;
            border-radius: 6px;
        }
    }


    ::v-deep .el-card {
        border: none;
        border-bottom: 1px solid #cccccc;
    }

    ::v-deep .el-card.is-always-shadow,
    .el-card.is-hover-shadow:focus,
    .el-card.is-hover-shadow:hover {
        box-shadow: none;
    }
</style>

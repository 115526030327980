<template>
    <div class="amend-dialog">
        <el-dialog title="错误反馈" :visible.sync="amendDialogShow" :show-close="false" width="30%" :lock-scroll="false">
            <el-form :model="form" :rules="rules" ref="form">
                <el-form-item prop="amendText">
                    <el-input type="textarea"
                              v-model="form.amendText" placeholder="请详细描述题目错误的地方，采纳后可获得奖励会员哦~"
                              :rows="6"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeAmendDialog">取 消</el-button>
                <el-button type="primary" @click="submitAmend">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {
        addAmend,
    } from '@/api/index.js'

    export default {
        name: "amendDialog",
        props: ['amendDialogShow', 'questionId'],
        data() {

            return {
                form: {
                    questionId: '',
                    amendText: ''
                },
                rules: {
                    amendText: [
                        {required: true, message: '错误描述不能为空！', trigger: 'blur'}
                    ]
                }
            }
        },
        methods: {
            closeAmendDialog() {
                this.$emit('closeAmendDialog');
                this.form.questionId = '';
                this.form.amendText = '';
                this.resetForm();
            },

            submitAmend() {
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        this.$message.success("提交成功！");
                        this.form.questionId = this.questionId;
                        addAmend(this.form).then(res => {

                        });
                        this.closeAmendDialog();
                    }
                });
            },
            resetForm() {
                this.$refs.form.resetFields();
            }
        }
    };
</script>


<style>

</style>

<template>
    <div class="bottom">
        <div class="sub_bottom">
            <div v-for="item in bottomInfo" :key="item.title" style="width: 25%;text-align: center;">
                <h3 class="titleSty">{{ item.title }}</h3>
                <div class="childSty">
                    <p v-for="item1 in item.childArr" :key="item1.name" @click="clickBottomItem(item1.name)">
                        {{ item1.name }}
                    </p>
                </div>
            </div>
        </div>
        <!--    https://beian.miit.gov.cn/#/Integrated/index-->
        <div class="footerContext">
            <div @click="ToOtherPage"></div>
            <a href="https://beian.miit.gov.cn/" target="_blank" class="beian">网站备案编号： 沪ICP备20006544号-1</a>
            <br/>
            <br/>
            <div>Copyright © 2019~2025, WANCODE Education and Technology Co., Ltd.（Shanghai）All Rights
                Reserved.上海玩扣教育科技有限公司 提供技术支持
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                bottomInfo: [{
                    title: "关于我们",
                    titleLink: "",
                    childArr: [
                        //     {
                        //   name: "公司简介",
                        //   childLink: ""
                        // },
                        {
                            name: "教师版",
                            childLink: ""
                        }, {
                            name: "加入我们",
                            childLink: ""
                        }]
                },{
                        title: "联系我们",
                        titleLink: "",
                        childArr: [{
                            name: "联系我们",
                            childLink: ""
                        }, {
                            name: "学习交流",
                            childLink: ""
                        }]
                    },{
                        title: "新手帮助",
                        titleLink: "",
                        childArr: [
                            {
                                name: "客服微信",
                                childLink: ""
                            }, {
                                name: "使用手册",
                                childLink: ""
                            }]
                    },{
                        title: "其他服务",
                        titleLink: "",
                        childArr: [{
                            name: "意见反馈",
                            childLink: ""
                        }, {
                            name: "忘记密码",
                            childLink: ""
                        }
                            // ,{
                            //   name: "考试报名",
                            //   childLink: ""
                            // }
                        ]
                    },{
                        title: "服务协议",
                        titleLink: "",
                        childArr: [{
                            name: "用户协议",
                            childLink: ""
                        }, {
                            name: "隐私协议",
                            childLink: ""
                        }, {
                            name: "免责声明",
                            childLink: ""
                        }]
                    },
                ]
            }
        },
        methods: {
            clickBottomItem(item) {
                this.$emit("clickBottomItem", item)
            },
            ToOtherPage() {

            }
        }
    }
</script>

<style lang="scss" scoped>


    .bottom {
        width: 100%;
        background-color: #474443;
        margin-top: 100px;

        /*position: absolute; !* 使用固定定位 *!*/
        /*left: 0;*/
        /*bottom: 0; !* 位于底部 *!*/

        .sub_bottom {
            width: 1200px;
            height: 100px;
            margin: 0 auto;
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            justify-content: space-between;
            padding: 20px 0;
            /*// text-align: center;*/

            .titleSty {
                font-size: 16px;
                line-height: 50px;
                color: #fff;
            }

            .childSty {
                color: #ccc;

                p {
                    font-size: 14px;
                    line-height: 30px;
                }

                p:hover {
                    cursor: pointer;
                    color: skyblue;
                }
            }

        }

        .footerContext {
            height: auto;
            text-align: center;
            padding: 20px 0;
            color: #ccc;


        }

        .footerContext .beian {
            text-decoration: none;
            color: #ccc;
        }
    }

</style>

<template>
    <div class="analysis-dialog">
        <el-dialog title="解析补充" :visible.sync="analysisDialogShow" :show-close="false" width="30%" :lock-scroll="false">
            <el-form :model="analysisForm" :rules="rules" ref="analysisForm">
                <el-form-item prop="analysisText">
                    <el-input type="textarea" v-model="analysisForm.analysisText"
                              placeholder="请补充题目解析或纠正解析，采纳后可获得奖励会员哦~"
                              :rows="6"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="closeAnalysisDialog">取 消</el-button>
                <el-button type="primary" @click="submitAnalysis">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {
        addAnalysis,
    } from '@/api/index.js'

    export default {
        name: "analysisDialog",
        props: ['analysisDialogShow', 'questionId'],
        data() {

            return {
                analysisForm: {
                    questionId: '',
                    analysisText: ''

                },
                rules: {
                    analysisText: [{required: true, message: '解析描述不能为空！', trigger: 'blur'}]
                }
            }
        },
        methods: {
            closeAnalysisDialog() {
                this.$emit('closeAnalysisDialog');
                this.resetForm();
            },
            submitAnalysis() {
                this.$refs.analysisForm.validate((valid) => {
                    if (valid) {

                        this.$message.success("提交成功！");
                        this.analysisForm.questionId = this.questionId;
                        addAnalysis(this.analysisForm).then(res => {

                        })
                        this.closeAnalysisDialog();
                    }
                });
            },
            resetForm() {
                this.$refs.analysisForm.resetFields();
            }


        }
    };
</script>


<style lang="scss" scoped>

</style>

import axios from 'axios'
import App from "../App.vue";
import {
	MessageBox,
	Message
} from 'element-ui'

import router from "../router";
// import store from '@/store'
// import { getToken } from '@/utils/auth'
// create an axios instance
axios.defaults.withCredentials = true;
const service = axios.create({
	//生产地址
	baseURL: 'http://www.wancode.net:8010', // url = base url + request url
	//开发地址
	// baseURL: 'http://localhost:8010', // url = base url + request url
	// baseURL: 'WanCodeExam', // url = base url + request url
	withCredentials: true, // send cookies when cross-domain requesrts
	timeout: 30000 // request timeout
})

// request interceptor
service.interceptors.request.use(
	config => {
		// do something before request is sent

		if (localStorage.getItem("token")) {
			// let each request carry token
			// ['X-Token'] is a custom headers key
			// please modify it according to the actual situation
			// config.headers.post['Content-Type']= 'application/x-www-form-urlencoded;'
			config.headers['token'] = localStorage.getItem("token")
		}
		if (localStorage.getItem("sessionId")) {
			// let each request carry token
			// ['X-Token'] is a custom headers key
			// please modify it according to the actual situation
			// config.headers.post['Content-Type']= 'application/x-www-form-urlencoded;'
			config.headers['sessionId'] = localStorage.getItem("sessionId")
		}
		return config
	},
	error => {
		// do something with request error
		return Promise.reject(error)
	}
)

// response interceptor
service.interceptors.response.use(
	/**
	 * If you want to get http information such as headers or status
	 * Please return  response => response
	 */

	/**
	 * Determine the request status by custom code
	 * Here is just an example
	 * You can also judge the status by HTTP Status Code
	 */
	response => {
		const res = response.data
		// console.log(response.config.url)
		// console.log(response.config.data)

		// if the custom code is not 20000, it is judged as an error.
		if(res.message === "答题次数达到每日答题上限！可升级会员!"){
			return res
		}
		if (res.code === "9998"){
			//登录失败
			return res
		}
		if(res.code === "99995"){
			return res
		}else if(res.code === "99996"){
			return res
		}else if (res.type=='application/pdf'){
			return res
		}
		if (res.code !== "0000") {
			Message({
				message: res.message || 'Error',
				type: 'error',
				duration: 2 * 1000,
				offset: 80
			})

			if (res.code === "0010") {
				if (response.config.url === '/wancode/exam/answer/submit') {
					localStorage.setItem("underSend", response.config.data)
				}
				// localStorage.setItem("token", "");
				showLogin();
			}

			// 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
			if (res.code === 50008 || res.code === 50012 || res.code === 50014 || res.code === 50014) {
				// to re-login
				MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again',
					'Confirm logout', {
						confirmButtonText: 'Re-Login',
						cancelButtonText: 'Cancel',
						type: 'warning'
					}).then(() => {
					store.dispatch('user/resetToken').then(() => {
						location.reload()
					})
				})
			}

			return Promise.reject(new Error(res.message || 'Error'))
		} else {
			return res
		}
	},
	error => {
		// console.log('err' + error) // for debug
		Message({
			message: error.message,
			type: 'error',
			duration: 5 * 1000
		})
		return Promise.reject(error)
	}
)

export default service

<template>
    <div class="paper-left">
        <p class="paper-name">{{testPaperInfo.name}}</p>
        <div class="paper-des">
            <p style="font-size: 12px;">总分:{{testPaperInfo.score}}</p>
            <p style="font-size: 12px;">时长:{{testPaperInfo.suggestTime}}分钟</p>
        </div>
        <el-divider></el-divider>
        <div v-for="(item,index) in allQuestionArr" :key="index">
            <div style="font-size: 14px;margin: 12px 0;color: #000">
                {{item.name}}
            </div>
            <div>
                <!-- <a :href="'#' + item" v-for="item in allNumberArr" :key="item"> -->
                <a @click="clickanchor(`#q${item}`)" v-for="item in item.questionOrder" :key="item">
                    <el-button size="mini" type="infor" class="subjetButton">
                        {{item}}
                    </el-button>
                </a>
            </div>
        </div>

        <div class="headerSty">
            <div class="headerTime">
                <p>
                    剩余时间:
                </p>
                <p>
                    {{timeShow}}
                </p>
            </div>
            <div style="margin-top: 20px;width: 100%;text-align:center;">
                <el-button  type="danger" @click="cancelSubmit" style="margin-right: 5px">取消答题</el-button>
                <el-button  type="primary" @click="submitSubject">提交试卷</el-button>


            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            suggestTime: {
                type: Number,
                default: 60
            },
            testPaperInfo: {
                type: Object,
                default: () => ({})
            },
            allNumberArr: {
                type: Array,
                default: () => []
            },
            answerArr: {
                type: Array,
                default: () => []
            },
            allQuestionArr: {
                type: Array,
                default: () => []
            }
        },
        watch: {
            answerArr: {
                handler: function (n, o) {
                    let idArr = [];
                    this.answerArr.forEach(item => {
                        idArr.push(item.id)
                    })
                    // let butonArr = document.getElementsByClassName("subjetButton");
                    const elements = this.$el.getElementsByClassName('subjetButton');
                    const elementsArray = Array.from(elements);
                    elementsArray.forEach((item) => {
                        // console.log(item)
                        let innerHTMLValue = item.getElementsByTagName("span")[0].innerHTML
                        if (idArr.indexOf(parseInt(innerHTMLValue)) != -1) {
                            item.classList.remove("el-button--infor"); // 设置
                            item.classList.add("el-button--success"); // 设置
                        } else {
                            item.classList.remove("el-button--success"); // 设置
                            item.classList.add("el-button--infor"); // 设置
                        }
                        // if(this.answerArr.indexOf(innerHTMLValue))
                    })
                },
                deep: true
            },
            allSecond: {
                handler: function (n, o) {
                    if (this.allSecond >= 0) {
                        this.timeShow = this.formatTime(this.allSecond)
                    } else {
                        clearInterval(this.lock);
                        this.forceSubmitSubject();
                        this.$alert('考试时间到', '提示', {
                            showClose: false,
                            closeOnClickModal: false,
                            confirmButtonText: '确定',
                            callback: action => {

                                this.$router.push({
                                    name: "examinationRecord"
                                })
                            }
                        });
                    }
                }
            },
            suggestTime: {
                handler: {
                    handler: function (n, o) {
                        if (this.suggestTime) {
                            this.startTime()
                        }
                    }
                }
            }
        },
        data() {
            return {
                allSubject: [],
                time: 60,
                allSecond: null,
                timeShow: "",
                lock: null
            }
        },
        methods: {
            isAllWrite() {
                let isOver = true
                // let butonArr = document.getElementsByClassName("subjetButton");
                const elements = this.$el.getElementsByClassName('subjetButton');
                const elementsArray = Array.from(elements);
                elementsArray.forEach((item) => {
                    let innerHTMLValue = item.getElementsByTagName("span")[0].innerHTML
                    // console.log(item.classList)
                    item.classList.forEach(item1 => {
                        if (item1 == "el-button--infor") {
                            isOver = false
                        }
                    })
                })
                return isOver
            },
            clickanchor(idName) {
                this.$emit("changeMaodian", idName);
            },
            formatSubject() {
                // this.
            },

            submitSubject() {
                this.isAllWrite();
                if (this.isAllWrite()) {
                    this.$emit("submitPaper", parseInt(this.allSecond / 60))
                } else {
                    this.$message.warning("请回答完题目")
                }

                // this.$emit("submitPaper", parseInt(this.allSecond / 60))
            },
            cancelSubmit() {
                this.$confirm('确定退出，取消答题?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    // this.$router.go(-1)
                    window.close()
                }).catch(() => {

                });
            },
            //时间到，强制提交
            forceSubmitSubject(){
                console.log("时间到，强制提交", parseInt(this.suggestTime));
                this.$emit("submitPaper", parseInt(this.suggestTime))
            },
            startTime() {
                this.time = Number(this.suggestTime)
                // console.log(this.suggestTime)
                this.allSecond = Number(this.time) * 60;
                this.lock = window.setInterval(() => {
                    this.allSecond--;
                }, 1000)
            },
            formatTime(time) {
                let sec = this.addZero(time % 60);
                let min = this.addZero(parseInt(time / 60));
                return `${min}:${sec}`
            },
            addZero(num) {
                if (num == 0) {
                    return "00"
                } else if (num < 10) {
                    return "0" + num
                } else {
                    return num
                }
            }
        },
        beforeDestroy() {
            clearInterval(this.lock)
        }
    }
</script>

<style lang="scss" scoped>
     ::v-deep .el-divider--horizontal {
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .paper-left {
        width: 280px;
        color: #000;
        /*padding-left: 10px;*/
        padding: 20px 10px 0 10px;
        /*overflow-y: scroll;*/


        .paper-name {
            width: 270px;
			font-size: 16px;
            color: #222;
            font-weight: 600;
            word-wrap: break-word; /* 旧版本浏览器兼容性 */
            overflow-wrap: break-word; /* 标准属性 */
            padding: 0 10px;
        }

		.paper-des{
            width: 255px;
			display: flex;
			flex-wrap: nowrap;
			flex-direction: row;
			justify-content: space-between;
			margin-top: 10px;
            padding-left: 10px;
		}

    }

    .subjetButton {
        margin-left: 6px;
        margin-top: 6px;
        width: 48px;
        height: 36px;
        border-radius: 4px;
        font-size: 14px;

        &:hover{
            cursor: pointer;
        }
    }

    .headerSty {
        // display: flex;
        // flex-wrap: nowrap;
        // flex-direction: row;
        // justify-content: space-between;
        margin-top: 30px;

        .headerTime {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            justify-content: center;
            // text-align: center;

            p:first-child {
                font-size: 20px;
            }

            p:last-child {
                font-size: 20px;
                color: red;
                font-weight: 900;
            }
        }
    }
</style>

<template>
    <div>
        <div>
            <el-card class="box-card">
                <div slot="header" class="subjectTitle" style="line-height: 36px;">
                    <div class="subjectName">
                        <span style="font-size: 18px;" v-if="subjectId !=null">{{subjectId}}.</span>
                        <div class="editor-content-view">
                            <div v-html="questionBody.title" style="font-size: 18px;" v-safe></div>
                        </div>
                    </div>
                </div>
                <div style="margin-top: -26px;margin-bottom: 10px;">
                    <el-radio-group v-model="radio" :disabled="isDis">
                        <el-radio :label="item.prefix" v-for="(item,index) in questionBody.items" :key="item.prefix"
                                  style="margin-top: 20px;font-size: 16px">
                            <div style="display: flex;font-size: 16px;">
                                {{item.prefix}}. &nbsp;
                                <div v-html="item.content" style="font-size: 16px;"></div>
                            </div>
                        </el-radio>
                    </el-radio-group>
                </div>

                <div v-if="haveAnswer" class="answer-style">
                    <div class="answer-div">
                        <p class="content"><span>标准答案:</span>
                            <span style="margin-left: 5px;" v-html="questionBody.correct"></span>
                        </p>
                        <div style="display: flex;">
                            <span class="content"><span>结果:</span></span>
                            <el-tag size="mini" v-if="questionBody.answerInfo.doRight" type="success"
                                    style="margin-top: 20px;margin-left: 5px;">正确
                            </el-tag>
                            <el-tag size="mini" v-if="!questionBody.answerInfo.doRight" type="danger"
                                    style="margin-top: 20px;margin-left: 5px;">错误
                            </el-tag>
                        </div>
                        <p class="content"><span>得分:</span>
                            <span style="margin-left: 5px;">{{questionBody.answerInfo.score}} / {{questionBody.score}}</span>
                        </p>
                        <p class="content">
                            <span>得分率:</span>
                            <span style="margin-left: 5px;">{{parseInt(questionBody.answerInfo.score / questionBody.score) * 100}}%</span>
                        </p>
                        <div style="display: flex;">
                            <p class="content"><span>难度:</span></p>
                            <el-rate v-model="questionBody.difficult" style="margin-top: 20px;margin-left: 5px;"
                                     disabled=""></el-rate>
                        </div>
                        <p class="content" style="margin-bottom: 10px">
                            <span>解析:</span>
                            <span v-html="questionBody.analyze" style="margin-left: 5px;margin-bottom: 10px"></span>
                        </p>
                    </div>
                    <div class="cardButton">
                        <span @click="openAnalysisDialog()" style="color:red">解析补充</span>
                        <span @click="openAmendDialog()" style="color:red">纠错</span>
                        <span @click="collectQuestion()" style="color:green">收藏</span>
                    </div>
                </div>
            </el-card>
        </div>
        <amendDialog :amendDialogShow="amendDialogShow"
                     :questionId="this.questionBody.id"
                     @closeAmendDialog="closeAmendDialog">
        </amendDialog>

        <analysisDialog :analysisDialogShow="analysisDialogShow" :questionId="this.questionBody.id"
                        @closeAnalysisDialog="closeAnalysisDialog"></analysisDialog>
    </div>
</template>

<script>
    import amendDialog from "@/components/amendDialog.vue"
    import analysisDialog from "@/components/analysisDialog.vue"

    export default {
        components: {
            analysisDialog,
            amendDialog
        },
        props: {
            subjectId: {
                type: Number,
                default: 1
            },
            isDis: {
                type: Boolean,
                default: false
            },
            questionBody: {
                type: Object,
                default: () => ({})
            },
            haveAnswer: {
                type: Boolean,
                default: false
            },
            chooseItem: {
                type: String,
                default: ""
            }
        },
        mounted() {
            // console.log(this.questionBody)
            if (this.haveAnswer) {
                this.radio = this.questionBody.answerInfo.content
            }
        },
        watch: {
            radio: {
                handler: function (n, o) {
                    if (this.haveAnswer) {

                    } else {
                        if (this.radio) {
                            this.$emit("changed", {
                                id: this.subjectId,
                                name: this.radio,
                                questionId: this.questionBody.id,
                                content: this.radio,
                                contentArray: [],
                                itemOrder: this.subjectId
                            })
                        } else {
                            this.$emit("unchanged", {
                                id: this.subjectId,
                                name: this.radio
                            })
                        }
                    }

                }
            }
        },
        data() {
            return {
                radio: "",
                value1: 4.5,
                amendDialogShow: false,
                analysisDialogShow: false
            }
        },
        methods: {
            openAmendDialog() {
                this.amendDialogShow = true;
            },
            closeAmendDialog() {
                this.amendDialogShow = false;
            },
            openAnalysisDialog() {
                this.analysisDialogShow = true;
            },
            closeAnalysisDialog() {
                this.analysisDialogShow = false;
            },
            collectQuestion() {
                this.$message({
                    type: 'success',
                    message: '收藏成功！'
                });
            }
        }
    }
</script>

<style lang="scss" scoped>

    .editor-content-view {

    }

    :deep(.editor-content-view pre) {
        word-wrap: normal;
        font-family: Consolas, Monaco, Andale Mono, Ubuntu Mono, monospace;
        background-color: #F5F2F0;
        /*border: 3px solid #ccc;*/
        border-radius: 5px;
        padding: 10px 10px;
        margin-top: 5px;
        /*margin-bottom: 10px;*/
        overflow-x: auto;
        font-size: 16px;
        line-height: 1.5;
        min-width: 750px;
        tab-size: 4;
    }

    :deep(.editor-content-view pre) {
        /*background-color: #eee;*/
        /*padding: 5px;*/
        /*border-radius: 3px;*/
    }

    /* 确保sub和sup标签被正确显示 */
    ::v-deep sub {
        vertical-align: sub;
        font-size: smaller;
    }

    ::v-deep sup {
        vertical-align: super;
        font-size: smaller;
    }

    ::v-deep .el-radio {
        display: flex;
        margin-top: 5px;
    }

    .subjectName {
        display: flex;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;

        p {
            font-size: 16px;
        }
    }

    .answer-style {
        position: relative;
        border: 1px solid #eee;
        border-radius: 5px;
        line-height: 24px;
        margin: 20px 0 10px 0;
        padding-left: 15px;
        font-size: 15px;
        color: #555;
        background: #fcfcfc;

        .cardButton {
            position: absolute;
            right: 10px;
            top: 10px;

            span {
                padding-top: 10px;
                padding-left: 10px;
                padding-right: 10px;
                /*color: #FFFFFF;*/

                &:hover {
                    cursor: pointer;
                }
            }
        }

    }


    .content {
        margin-top: 20px;
        display: flex;

        span {
            color: #000;
            line-height: 20px;
        }
    }

</style>

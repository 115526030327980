<template>
    <div class="page">
        <el-container style="padding: 20px 0;border-bottom: 1px solid #f4f4f4;">
            <el-aside>
                <asideLeft :testPaperInfo="testPaperInfo" :answerArr="answerArr" :allQuestionArr="allQuestionArr"
                           @submitPaper="submitPaper" :suggestTime="suggestTime" @changeMaodian="changeMaodian">
                </asideLeft>
            </el-aside>
            <el-container v-loading="loading" element-loading-text="试卷加载中.....">
                <el-main>
                    <mainDetails ref="mainDetails" @isAnswerArr="isAnswerArr" :allQuestionArr="allQuestionArr"
                                 :paperTitle="paperTitle" :maodian="maodian"></mainDetails>
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>

<script>
    import asideLeft from "./components/asideLeft.vue"
    import headerDetails from "./components/headerDetails.vue"
    import mainDetails from "./components/mainDetails.vue"
    import {
        getPaperDetails
    } from "@/api/index.js"


    export default {
        components: {
            asideLeft,
            headerDetails,
            mainDetails
        },
        data() {
            return {
                allNumberArr: [],
                answerArr: [],
                allQuestionArr: [],
                paperTitle: '',
                testPaperId: null,
                testPaperInfo: {},
                suggestTime: null,
                loading: true,
                maodian: '#1'
            }
        },
        created() {
            // this.$nextTick(() => {
            // 	// 禁用右键
            // 	document.oncontextmenu = new Function("event.returnValue=false");
            // 	// 禁用选择
            // 	document.onselectstart = new Function("event.returnValue=false");
            // });
        },
        mounted() {
            this.testPaperId = this.$route.query.id;
            this.getInfo(this.testPaperId)
        },
        methods: {
            //定位到选中的题目的位置
            changeMaodian(idName) {
                this.maodian = idName
            },
            isAnswerArr(item) {
                this.answerArr = item
            },
            getInfo(id) {
                this.loading = true
                getPaperDetails(id).then(res => {
                    // console.log(res)
                    if (res.code === "0000") {
                        this.testPaperInfo = res.data
                        this.paperTitle = res.data.name
                        this.allQuestionArr = res.data.titleItems

                        this.suggestTime = res.data.suggestTime
                        let numberArr = []
                        res.data.titleItems.forEach(item => {
                            if (item.questionItems) {
                                item.questionItems.forEach(item1 => {
                                    numberArr.push(item1.itemOrder)
                                })
                            }

                        })
                        this.allNumberArr = numberArr
                        this.loading = false
                    }
                }).catch(() => {
                    this.loading = false

                    if (localStorage.getItem("token")) {
                    } else {
                        showLogin();
                    }

                    this.$router.push({
                        path: '/'
                    })

                    // this.loading = false
                    // this.$confirm('此作品，非VIP用户不可操作', '提示', {
                    //     confirmButtonText: '确定',
                    //     cancelButtonText: '开通会员',
                    //     type: 'warning'
                    // }).then(() => {
                    //     window.close()
                    // }).catch(() => {
                    //     this.$router.push({
                    //         path: '/selfVIP'
                    //     })
                    // });
                });
            },
            submitPaper(time) {
                this.$refs.mainDetails.submitSubject(this.testPaperInfo.id, time);
            }
        }
    };
</script>

<style lang="scss" scoped>
    .page,
    .el-container {
        background-color: #EFEFEF;
    }


    .el-aside {
        color: #333;
        width: 300px;
        height: 70vh;
        background-color: #FFFFFF !important;
        /*background-color: rgb(238, 241, 246);*/
        padding-bottom: 10px;
        border-radius: 6px;
        /*overflow-x: hidden*/
        overflow: hidden;
        scrollbar-width: none; /* 针对FireFox */
        box-shadow: 0 1px 3px rgba(26, 26, 26, 0.1);
        position: fixed;
        top: 20;
        left: 300;
    }

    .el-main {
        margin-left: 315px;
        background-color: #FFFFFF !important;
        width: 945px;
        height: 100%;
        overflow: visible;
        border-radius: 6px;
        box-shadow: 0 1px 3px rgba(26, 26, 26, 0.1);

    }

</style>
